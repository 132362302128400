/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@import '../../_library/shared/_styles/modal.scss';
@import '../../_library/shared/_styles/fade-in.scss';
@import '../../_library/shared/_styles/buttons.scss';
@import '../../_library/shared/_styles/headers.scss';
@import 'aos/dist/aos.css';

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,500&family=Oswald:wght@700&display=swap');
body {
  margin: 0;
  font-family: "Roboto Light 300", sans-serif !important;
  background-color: #ffffff;
  color: rgb(42, 42, 42);
}
//:root {
//    --accent-color: #448ec0;
//}
.Ubuntu {
  font-family: "Ubuntu", serif !important;
}

.DMSans{
    font-family: 'DM Sans', sans-serif !important;
}

.Oswald {
    font-family: 'Oswald', Arial, Helvetica, sans-serif !important;
}

.active {
    border-bottom: 2px solid #448ec0
}

.new-logo {
    display: none;
}
.categories-container {
    position: relative;
}
.category-li {
    position: relative;
}

.submenu-container {
    position: absolute;
    top: -36px;
    left: 0;
    height: 298px;
    background-color: #fff;
    transition: transform 0.5s ease-in-out;
    transform: translateX(100%);
    z-index: 1000;
}

@keyframes slideIn {
    from {
        transform: translateX(100vw);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes slideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100vw);
    }
}
.submenu-container.open {
    animation: slideIn 0.5s forwards;
}
.submenu-container.closing {
    animation: slideOut 0.5s forwards;
}
.submenu-container ul li a {
    text-align: left;
    padding: 0;
    display: block;
    background-color: white;
}
.submenu-container.visible {
    transform: translateX(0);
}
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.progress-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: #FFC100;
    z-index: 100;
}

.scrollbar::-webkit-scrollbar {
    width: 12px;
    margin-left: 2px;
}

.scrollbar::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #878787;
    border-radius: 10px;
    border: 3px solid rgba(174, 174, 174, 0.1);
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.scrollbar::-webkit-scrollbar-button:hover {
    background-color: #AAA;
}

.scrollbar::-webkit-scrollbar-button:single-button {
    height: 12px;
    background-repeat: no-repeat;
    background-position: center;
}

.scrollbar::-webkit-scrollbar-button:single-button:vertical:decrement {
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="%23666" d="M7 14l5-5 5 5H7z"/></svg>');
}

.scrollbar::-webkit-scrollbar-button:single-button:vertical:increment {
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="%23666" d="M7 10l5 5 5-5H7z"/></svg>');
}

.scrollbar::-webkit-scrollbar-button:single-button:vertical:increment:disabled,
.scrollbar::-webkit-scrollbar-button:single-button:vertical:decrement:disabled {
    background-color: transparent;
    background-image: none;
}

@media screen and (min-width: 1023px) and (max-width: 1740px) {
    .custom-logo {
        display: none;
    }
    .new-logo {
        display: block;
    }
    .left-bar{
        display:flex;
        align-items: center;
    }

}
@media (min-width: 1023px) {
    .dropdown {
        top: 50px;
    }
}

@media (max-width: 1023px) {
    .w-176px {
        width: 176px;
    }
    input::placeholder {
        font-size: 12px;
    }
}

@media (max-width: 640px) {
    .scrollbar::-webkit-scrollbar {
        width: 6px;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        border-width: 1px;
    }
}
